<template>


  <div class="row">
    <div class="col contentPreview">

      <div class="row">
        <div class="col head-preview text-center bg-success text-white w-100 p-2 m-0">
          Pré visualização
        </div>
      </div>


      <div class="row">
        <template v-if="data.coverImg === ''">
          <div class="col"
            style="background-image: url('https://universoretro.com.br/wp-content/uploads/2020/10/salao-new-order-hair.jpg');background-size: cover;height: 250px;background-repeat: none;">
          </div>
        </template>

        <template v-else>
          <div class="col" :style="{ backgroundImage: `url('${data.coverImg}')`, backgroundSize: 'cover', height: '250px', backgroundRepeat: 'no-repeat' }">
          </div>
        </template>
      </div>



      <div class="row justify-content-center" id="main">
        <div class="col-11">

          <div class="card shadow p-2 mb-3 bg-white">
            <div class="text-center mb-2">

              <template v-if="data.logoImg === ''">
                <img class="rounded-circle img-thumbnail img-thumb-business"
                  src="https://mutari.com.br/2020/wp-content/uploads/2021/11/cabeleireiro.png" width="95" height="95"
                  alt="">
              </template>

              <template v-else>
                <img class="rounded-circle img-thumbnail img-thumb-business" :src="`${data.logoImg}`" width="95"
                  height="95" alt="">
              </template>

              <h5>{{ (data.businessName !== '') ? data.businessName : 'Nome do negócio' }}</h5>
              <div class="h6 font-weight-300"><i class="ni location_pin mr-2"></i>{{ (data.businessAddress !== '') ?
                data.businessAddress : 'Endereço do negócio' }}</div>
            </div>
            <div class="card-body">

              <div v-if="step === 1">

                <div class="row p-2">
                  <!-- Step 1: Escolher o Profissional -->
                  <h3 class="mb-3">Passo 1: Escolha o Profissional</h3>

                  <ul class="list-group list-group-light p-0 mt-4 mb-4">

                    <li class="list-group-item d-flex justify-content-between align-items-center"
                      v-for="professional in professionals" :key="professional.id">
                      <div class="d-flex align-items-center">
                        <img src="https://mdbootstrap.com/img/new/avatars/8.jpg" alt=""
                          style="width: 45px; height: 45px" class="rounded-circle" />
                        <div class="ms-3">
                          <p class="fw-bold mb-1">{{ professional.name }}</p>
                          <p class="text-muted mb-0">Disponível</p>
                        </div>
                      </div>
                      <button class="btn btn-primary rounded-pill"
                        @click="selectProfessional(professional.id)">Selecionar</button>
                    </li>

                  </ul>
                </div>


                <div class="row p-2">
                  <button class="btn btn-primary rounded-pill  mb-3" width="100%" @click="selectProfessional(0)">
                    QUALQUER
                    PROFISSIONAL</button>
                </div>

              </div>

              <div v-else-if="step === 2">

                <div class="row p-2">

                  <!-- Step 2: Escolher o Serviço -->
                  <h3 class="mb-3">Passo 2: Escolha o Serviço</h3>

                  <ul class="list-group list-group-light p-0 mt-4 mb-4">

                    <li class="list-group-item d-flex justify-content-between align-items-center"
                      v-for="service in services" :key="service.id">
                      <div class="d-flex align-items-center">
                        <img src="https://mdbootstrap.com/img/new/avatars/8.jpg" alt=""
                          style="width: 45px; height: 45px" class="rounded-circle" />
                        <div class="ms-3">
                          <p class="fw-bold mb-1">{{ service.name }}</p>
                          <p class="text-muted mb-0">R$ {{ service.price }} - {{ service.time }}</p>
                        </div>
                      </div>
                      <button class="btn btn-primary rounded-pill"
                        @click="selectService(service.id)">Selecionar</button>
                    </li>

                  </ul>

                </div>

                <div class="row p-2">
                  <button class="btn btn-outline-secondary rounded-pill  mb-3" width="100%" @click="(step--)">VOLTAR
                  </button>
                </div>

              </div>

              <div v-else-if="step === 3">
                <div class="row p-2">
                  <!-- Step 3: Seleção de data -->
                  <h3 class="mb-3">Passo 3: Escolha a data do agendamento</h3>

                  <ul class="list-group list-group-light p-0 mt-4 mb-4">

                    <li class="list-group-item d-flex justify-content-between align-items-center"
                      v-for="(day, index) in days" :key="index">
                      <div class="d-flex align-items-center">
                        <div class="ms-3">
                          <p class="fw-bold mb-1">{{ day.day }}</p>
                          <p class="text-muted mb-0">{{ day.wekDay }}</p>
                        </div>
                      </div>
                      <button class="btn btn-primary rounded-pill" @click="selectDay(day.day)">Selecionar</button>
                    </li>

                  </ul>

                </div>

                <div class="row p-2">
                  <button class="btn btn-outline-secondary rounded-pill  mb-3" width="100%" @click="(step--)">VOLTAR
                  </button>
                </div>
              </div>

              <div v-else-if="step === 4">
                <div class="row">
                  <!-- Step 4: Seleção de horario -->
                  <h3 class="mb-3">Passo 4: Selecione o horário do seu agendamento</h3>

                  <ul class="list-group list-group-light p-0 mt-4 mb-4">

                    <li class="list-group-item d-flex justify-content-between align-items-center"
                      v-for="(time, index) in hours" :key="index">
                      <div class="d-flex align-items-center">
                        <div class="ms-3">
                          <p class="fw-bold mb-1">{{ time }}</p>
                        </div>
                      </div>
                      <button class="btn btn-primary rounded-pill" @click="selectHour(time)">Selecionar</button>
                    </li>

                  </ul>
                </div>

                <div class="row p-2">
                  <button class="btn btn-outline-secondary rounded-pill  mb-3" width="100%" @click="(step--)">VOLTAR
                  </button>
                </div>
              </div>

              <div v-else>
                <div class="row p-2">
                  <!-- Passo final: Confirmação -->
                  <h2>Agendamento Confirmado</h2>
                  <p>Profissional: {{ selectedProfessional }}</p>
                  <p>Serviço: {{ selectedService }}</p>
                  <p>Dia: {{ selectedDay }}</p>
                  <p>Horário: {{ selectedTime }}</p>
                </div>

                <div class="row p-2">
                  <button class="btn btn btn-outline-secondary rounded-pill  mb-3" width="100%" @click="(step = 1)">
                    REINICIAR AGENDAMENTO
                  </button>
                </div>
              </div>
            </div>

          </div>

          <div class="row pt-4 opacity-50 text-center" style="font-size: 14px;">
            <img src="@/assets/img/KedyAtende.png" alt="KedyAtende" class="mx-auto d-block img-fluid w-50">
            <p>www.kedyatende.com.br</p>
            <p>Sistema inteligênte de atendimento ao cliente.</p>
            <p><a href="#">Termos de Uso e Politicas de Privacidade</a></p>
          </div>

        </div>
      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: 'App',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      step: 1,
      professionals: [
        { id: 1, name: 'Profissional 1' },
        { id: 2, name: 'Profissional 2' },
        // Adicione mais profissionais se necessário
      ],
      selectedProfessional: '',
      services: [
        { id: 1, name: 'Corte de Cabelo', time: 30, price: 50 },
        { id: 2, name: 'Coloração', time: 60, price: 80 },
        // Adicione mais serviços se necessário
      ],
      days: [
        { wekDay: 'Qua', day: '08/05' },
        { wekDay: 'Qui', day: '09/05' },
        { wekDay: 'Sex', day: '10/05' }
      ],
      hours: ['10:00', '10:30', '13:00'],
      selectedService: '',
      // Defina os dias e horários disponíveis conforme necessário
      selectedDay: '',
      selectedTime: ''
    };
  },
  methods: {
    nextStep() {
      this.step++;
    },
    selectProfessional(id) {
      this.selectedProfessional = this.professionals.find(professional => professional.id === id).name;
      this.nextStep();
    },
    selectService(id) {
      this.selectedService = this.services.find(service => service.id === id).name;
      this.nextStep();
    },
    selectDay(day) {
      this.selectedDay = day;
      this.nextStep();
    },
    selectHour(time) {
      this.selectedTime = time;
      this.nextStep();
    },
    // Adicione métodos para os outros passos aqui
  }
};
</script>

<style scoped>
.contentPreview {
  background-color: #f1f1f1;
}

h3 {
  font-size: 21px !important;
}

#main {
  margin-top: -100px;
}

.img-thumb-business {
  margin-top: -50px;
}
</style>