<script>
import AgentsTable from "./components/AgentsTable.vue";
import { useToast } from "vue-toastification";

export default {
  components:{
    AgentsTable
  },
  data() {
    return {
      professionals: [],
      loading: false,
      toast: useToast(),
    };
  },
  created() {
    this.renderToast();
  },

  methods: {
    renderToast() {
      // Verifique se o parâmetro 'sucesso' está presente na query string da URL
      const toast = this.$route.query.toast;

      // Se 'sucesso' for true, exiba a mensagem de feedback ao usuário
      if (toast === 'success') {
        this.toastSuccess();
      }
    },

    toastSuccess() {
      this.toast.success("Agente atualizado com sucesso!", {
          position: "bottom-right",
          timeout: 5728,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
    },
  }
}


</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <router-link to="/add-agent" class="btn btn-light">ADICIONAR NOVO AGENTE</router-link>
      </div>
      <div class="col-12">
        <agents-table />
      </div>
    </div>
  </div>
</template>
