<template>
    <!-- Linha do tempo -->
    <div class="d-flex justify-content-between align-items-center mb-4">
        <div v-for="(step, index) in steps" :key="index" class="step-item">
            <div :class="['step-circle', { 'active': index === currentStep }]">{{ index + 1 }}</div>
            <div class="step-description">{{ step }}</div>
        </div>
    </div>

    <div class="row">

        <!-- PRIMEIRO STEP -->
        <div class="col-12">
            <div class="card mb-4">
                <div class="card-body">

                    <!-- PRIMEIRO STEP -->
                    <template v-if="currentStep === 0">

                        <div class="row">
                            <div class="col">
                                <img src="@/assets/img/profile-picture.jpg" alt="Imagem carregada"
                                    class="img-thumbnail rounded mx-auto d-block" style="height: 90px;"
                                    v-if="!selectedProfessional.photo">
                                <img :src="selectedProfessional.photo" alt="Imagem carregada"
                                    class="img-thumbnail rounded mx-auto d-block" style="height: 90px;"
                                    v-if="selectedProfessional.photo">
                            </div>
                        </div>

                        <div class="mb-3">
                            <label class="form-label" for="professionalName">Nome do profissional:</label>
                            <input class="form-control" type="text" id="professionalName"
                                v-model="selectedProfessional.name">
                        </div>

                        <div class="mb-3">
                            <label class="form-label" for="professionalDescription">Breve apresentação do
                                profissional:</label>
                            <input class="form-control" type="text" id="professionalDescription"
                                v-model="selectedProfessional.description">
                        </div>

                        <label class="form-label">Alterar foto de perfil do profissional:</label>
                        <UploadImg @imageUploaded="handleImageUploaded" />
                    </template>

                    <!-- SEGUNDO STEP -->
                    <template v-if="currentStep === 1">
                        <h4 class="text-center mb-4">Configure dias e Horários de Atendimento</h4>
                        <!-- Tabela para seleção de dias e horários de trabalho -->
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Dia da Semana</th>
                                        <th scope="col">H. Início</th>
                                        <th scope="col">H. Término</th>
                                        <th scope="col">H. Pausa</th>
                                        <th scope="col">Pausa (min)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="form-check"><input class="form-check-input" type="checkbox"
                                                    id="checkDay" v-model="monToFri" @change="toggleMonToFri"></div>
                                        </td>
                                        <td scope="row">Segunda a Sexta</td>
                                        <td><input type="time" class="form-control"
                                                v-model="selectedTimes[7].openingTime" :disabled="!monToFri"></td>
                                        <td><input type="time" class="form-control"
                                                v-model="selectedTimes[7].closingTime" :disabled="!monToFri"></td>
                                        <td><input type="time" class="form-control" v-model="selectedTimes[7].hasBreak"
                                                :disabled="!monToFri"></td>
                                        <td><input type="number" class="form-control"
                                                v-model="selectedTimes[7].breakDuration" :disabled="!monToFri"></td>
                                    </tr>

                                    <template v-if="!monToFri">
                                        <tr v-for="day in daysOfWeek.slice(1, 6)" :key="day.value">
                                            <td>
                                                <div class="form-check"><input class="form-check-input" type="checkbox"
                                                        id="checkDay" v-model="selectedTimes[day.value].checkDay"></div>
                                            </td>
                                            <td>{{ day.name }}</td>
                                            <td><input type="time" class="form-control"
                                                    v-model="selectedTimes[day.value].openingTime"></td>
                                            <td><input type="time" class="form-control"
                                                    v-model="selectedTimes[day.value].closingTime"></td>
                                            <td><input type="time" class="form-control"
                                                    v-model="selectedTimes[day.value].hasBreak"></td>
                                            <td><input type="number" class="form-control"
                                                    v-model="selectedTimes[day.value].breakDuration"></td>
                                        </tr>
                                    </template>

                                    <tr>
                                        <td>
                                            <div class="form-check"><input class="form-check-input" type="checkbox"
                                                    id="checkDay" v-model="selectedTimes[6].checkDay"></div>
                                        </td>
                                        <td>Sábado</td>
                                        <td><input type="time" class="form-control"
                                                v-model="selectedTimes[6].openingTime">
                                        </td>
                                        <td><input type="time" class="form-control"
                                                v-model="selectedTimes[6].closingTime">
                                        </td>
                                        <td><input type="time" class="form-control" v-model="selectedTimes[6].hasBreak">
                                        </td>
                                        <td><input type="number" class="form-control"
                                                v-model="selectedTimes[6].breakDuration"></td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div class="form-check"><input class="form-check-input" type="checkbox"
                                                    id="checkDay" v-model="selectedTimes[0].checkDay"></div>
                                        </td>
                                        <td>Domingo</td>
                                        <td><input type="time" class="form-control"
                                                v-model="selectedTimes[0].openingTime">
                                        </td>
                                        <td><input type="time" class="form-control"
                                                v-model="selectedTimes[0].closingTime">
                                        </td>
                                        <td><input type="time" class="form-control" v-model="selectedTimes[0].hasBreak">
                                        </td>
                                        <td><input type="number" class="form-control"
                                                v-model="selectedTimes[0].breakDuration"></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </template>


                    <!-- TERCEIRO STEP -->
                    <template v-if="currentStep === 2">
                        <h4 class="text-center mb-4">Selecione os Serviços que o Profissional Presta</h4>
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div v-for="(service, index) in availableServices" :key="index">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" :id="'service-' + index"
                                                    v-model="selectedServices" :value="service.id">
                                                <label class="form-check-label" :for="'service-' + index">{{
                                                    service.title }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="code">
                                <button class="btn btn-success" @click="submitSchedule">Salvar dados de
                                    Profissional</button>
                            </div>
                        </div>
                    </template>

                    <!-- Botões de navegação -->
                    <div class="d-flex justify-content-end mt-4">
                        <button v-if="currentStep > 0" @click="prevStep"
                            class="btn btn-secondary me-2">Anterior</button>
                        <button v-if="currentStep < steps.length - 1" @click="nextStep"
                            class="btn btn-primary">Próximo</button>
                    </div>

                </div>


            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import UploadImg from '@/views/components/UploadImg.vue';

export default {
    components: {
        UploadImg,
    },
    data() {
        return {
            currentStep: 0,
            steps: ["Profissional", "Agenda", "Serviços"],
            daysOfWeek: [
                { name: 'Domingo', value: 0 },
                { name: 'Segunda-feira', value: 1 },
                { name: 'Terça-feira', value: 2 },
                { name: 'Quarta-feira', value: 3 },
                { name: 'Quinta-feira', value: 4 },
                { name: 'Sexta-feira', value: 5 },
                { name: 'Sábado', value: 6 }],

            selectedTimes: [
                { openingTime: '08:00', closingTime: '12:00', hasBreak: false, breakDuration: 0 }, // Domingo
                { openingTime: '08:00', closingTime: '18:00', hasBreak: '12:00', breakDuration: 60 }, // Segunda-feira
                { openingTime: '08:00', closingTime: '18:00', hasBreak: '12:00', breakDuration: 60 }, // Terça-feira
                { openingTime: '08:00', closingTime: '18:00', hasBreak: '12:00', breakDuration: 60 }, // Quarta-feira
                { openingTime: '08:00', closingTime: '18:00', hasBreak: '12:00', breakDuration: 60 }, // Quinta-feira
                { openingTime: '08:00', closingTime: '18:00', hasBreak: '12:00', breakDuration: 60 }, // Sexta-feira
                { openingTime: '08:00', closingTime: '12:00', hasBreak: false, breakDuration: 0 }, // Sábado
                { openingTime: '08:00', closingTime: '12:00', hasBreak: '12:00', breakDuration: 60 }, // Segunda a sexta
            ],
            monToFri: false, // Checkbox para selecionar segunda a sexta-feira
            openingTime: '',
            closingTime: '',
            hasLunchBreak: false,
            hasNewProfessional: false,
            professionals: [], // Lista de profissionais cadastrados            
            newProfessional: '', // Novo profissional a ser criado
            availableServices: [], // Serviços disponíveis na conta do usuário
            selectedServices: [],
            selectedProfessional: { name: '', description: '', photo: '' },
            userSchedule: [],
            uploadedImageUrl: null,
        };
    },
    methods: {
        async fetchServices() {
            try {
                const auth = getAuth();
                const user = auth.currentUser;

                if (!user) {
                    console.error('User not authenticated.');
                    return;
                }

                const token = await user.getIdToken();

                const response = await axios.get(process.env.URL_API_ACTION_PAGE + 'service', {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                });

                this.availableServices = response.data;

            } catch (error) {
                console.error('Erro ao buscar os serviços:', error);
                this.toastError();
            }
        },
        nextStep() {
            // Processa interações de proximo passo
            switch (this.currentStep) {
                case 0:
                    if (this.hasNewProfessional) {
                        this.handleNewProfessional();
                    } else {
                        this.handleUpdateProfessional();
                    }
                    break;
                case 1:
                    this.prepareUserSchedule();
                    break;

                default:
                    break;
            }

            this.currentStep++;
        },

        handleImageUploaded(imageUrl) {
            this.selectedProfessional.photo = imageUrl;
            console.log('imageUrl: ', imageUrl);
        },

        prevStep() {
            this.currentStep--;
        },

        toggleHasNewProfessional() {
            this.hasNewProfessional = !this.hasNewProfessional;
        },
        toggleMonToFri() {
            // Habilitar/desabilitar a seleção de segunda a sexta-feira
            if (this.monToFri) {
                // Ocultar as opções de segunda a sexta-feira
                for (let i = 0; i < 5; i++) {
                    this.selectedTimes[i].openingTime = '';
                    this.selectedTimes[i].closingTime = '';
                    this.selectedTimes[i].hasBreak = false;
                    this.selectedTimes[i].breakDuration = 0;
                }
            }
        },
        async submitSchedule() {
            try {
                // Prepara os dados da agenda do usuário
                this.prepareUserSchedule();

                // Dados a serem enviados para o backend
                const data = {
                    selectedProfessional: this.selectedProfessional.id,
                    userSchedule: this.userSchedule,
                    services: this.selectedServices
                };

                this.loading = true;
                const auth = getAuth();
                const user = auth.currentUser;

                if (!user) {
                    console.error('User not authenticated.');
                    return;
                }

                const token = await user.getIdToken();

                const response = await axios.post(process.env.URL_API_ACTION_PAGE + 'professional/create-data', data, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                });

                this.$router.push({ path: '/professionals', query: { toast: 'success' } });
                console.log('Resposta do servidor:', response.data);

            } catch (error) {
                console.error('Erro ao enviar os dados:', error);
            } finally {
                this.loading = false;
            }
        },

        // Método para montar o objeto com as informações da agenda do usuário
        prepareUserSchedule() {
            this.userSchedule = [];

            // Adiciona os dias da semana de segunda a sexta, se estiverem selecionados
            if (this.monToFri) {
                this.userSchedule.push({
                    day: 7,
                    openingTime: this.selectedTimes[7].openingTime,
                    closingTime: this.selectedTimes[7].closingTime,
                    hasBreak: this.selectedTimes[7].hasBreak,
                    breakDuration: this.selectedTimes[7].breakDuration
                });
            }

            // Adiciona os dias individuais da semana
            for (let i = 0; i < 7; i++) {
                if (this.selectedTimes[i].checkDay) {
                    this.userSchedule.push({
                        day: this.daysOfWeek[i].value,
                        openingTime: this.selectedTimes[i].openingTime,
                        closingTime: this.selectedTimes[i].closingTime,
                        hasBreak: this.selectedTimes[i].hasBreak,
                        breakDuration: this.selectedTimes[i].breakDuration
                    });
                }
            }

            // Aqui você pode fazer o que quiser com this.userSchedule, como enviá-lo para o backend
            console.log('Agenda do usuário:', this.userSchedule);
        },

        async fetchProfessionalData() {
            try {
                this.loading = true;
                const auth = getAuth();
                const user = auth.currentUser;

                if (!user) {
                    console.error('User not authenticated.');
                    return;
                }

                const token = await user.getIdToken();
                const professionalId = this.$route.params.id;
                const response = await axios.get(process.env.URL_API_ACTION_PAGE + 'availability/by-professional/' + professionalId, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                });

                // Verifica se a resposta possui os dados esperados
                if (response.data.availabilities && response.data.availabilities.length > 0) {
                    // Loop através dos availabilities da API
                    response.data.availabilities.forEach(availability => {
                        // Encontre o objeto correspondente em selectedTimes pelo dayOfWeek
                        for (let i = 0; i < this.selectedTimes.length; i++) {

                            if (i === availability.dayOfWeek) {
                                // Atualize os valores correspondentes em selectedTimes
                                this.selectedTimes[i].openingTime = availability.startTime;
                                this.selectedTimes[i].closingTime = availability.endTime;
                                this.selectedTimes[i].hasBreak = '';
                                this.selectedTimes[i].breakDuration = '';
                                this.selectedTimes[i].checkDay = true; // Se necessário

                                // Se for uma semana
                                if (availability.dayOfWeek === 7) {
                                    this.monToFri = true;
                                }
                            }
                        }
                    });
                }

                this.selectedServices = response.data.services;
                this.selectedProfessional = response.data.professional;

            } catch (error) {
                console.error('Erro ao buscar serviços:', error);
            } finally {
                this.loading = false;
            }
        },

        checkProfessionalSelected() {
            // Verifica se o parâmetro 'id' está presente na rota
            if (this.$route.params.id) {
                this.fetchProfessionalData();
            } else {
                this.hasNewProfessional = true;
            }
        },

        async findProfessionals() {
            try {
                this.loading = true;
                const auth = getAuth();
                const user = auth.currentUser;

                if (!user) {
                    console.error('User not authenticated.');
                    return;
                }

                const token = await user.getIdToken();
                const response = await axios.get(process.env.URL_API_ACTION_PAGE + 'professional/', {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                });
                this.professionals = response.data;

                if (this.professionals.length == 0) {
                    this.hasNewProfessional = true;
                }

            } catch (error) {
                console.error('Erro ao buscar sessões:', error);
            } finally {
                this.loading = false;
            }
        },

        async handleNewProfessional() {

            try {
                this.loading = true;
                const auth = getAuth();
                const user = auth.currentUser;

                if (!user) {
                    console.error('User not authenticated.');
                    return;
                }

                const token = await user.getIdToken();
                const response = await axios.post(process.env.URL_API_ACTION_PAGE + 'professional/', {
                    name: this.selectedProfessional.name,
                    description: this.selectedProfessional.description,
                    photo: this.selectedProfessional.photo,
                },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    });

                console.log(response.data);
                this.selectedProfessional = response.data;
                this.newProfessional = '';
            } catch (error) {
                console.error('Erro ao buscar sessões:', error);
            }
        },

        async handleUpdateProfessional() {

            try {
                this.loading = true;
                const auth = getAuth();
                const user = auth.currentUser;

                if (!user) {
                    console.error('User not authenticated.');
                    return;
                }

                const token = await user.getIdToken();
                const response = await axios.put(process.env.URL_API_ACTION_PAGE + 'professional/' + this.selectedProfessional.id, {
                    name: this.selectedProfessional.name,
                    description: this.selectedProfessional.description,
                    photo: this.selectedProfessional.photo,
                },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    });

                console.log(response.data);
                this.selectedProfessional = response.data;
                this.newProfessional = '';
            } catch (error) {
                console.error('Erro ao buscar sessões:', error);
            }
        }
    },
    mounted() {
        this.checkProfessionalSelected();
        this.fetchServices();
    },
};
</script>

<style scoped>
.trash {
    font-size: 23px;
}

.trash:hover {
    cursor: pointer;
}

.step-item {
    display: flex;
    align-items: center;
}

.step-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.active {
    background-color: #007bff;
    color: white;
}
</style>