<style scoped>
.chat-container {
    height: 450px;
    overflow-y: auto;
}

.message {
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    word-wrap: break-word;
}

.user {
    background-color: #f0f0f0;
    text-align: right;
}

.agent {
    background-color: #cce5ff;
    text-align: left;
}

.input-container {
    display: flex;
    align-items: center;
    padding: 10px;
}

.btn-send {
    margin-left: 10px;
}

.icon-select-type {
    font-size: 38px
}

.card-border {
    border: 2px solid grey;
}

.card-border:hover {
    border-color: #2dce89;
    cursor: pointer;
}

.card-border:hover p {
    color: #2dce89;
}
</style>

<script>
import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";

import TimeServices from "@/views/modules/appointments/components/TimeServices.vue";

// import ArgonTextarea from "@/components/ArgonTextarea.vue";

export default {
    components: {
        TimeServices,
    },
    setup() {
        const store = useStore();

        onBeforeMount(() => {
            store.state.hideConfigButton = true;
            store.state.showNavbar = false;
            store.state.showSidenav = false;
            store.state.showFooter = true;
            document.body.classList.remove("bg-gray-100");
        });

        onBeforeUnmount(() => {
            store.state.hideConfigButton = false;
            store.state.showNavbar = true;
            store.state.showSidenav = true;
            document.body.classList.add("bg-gray-100");
        });

        return {};
    },

    data() {
        return {
        };
    },
    methods: {
        handleReturn() {
            this.$router.push({ name: 'Professionals' });
        }
    },
    async created() { }
};
</script>

<template>
    <main class="main-content mt-0">
        <div class="page-header align-items-start pt-3 pb-11 m-3 border-radius-lg" style="
        background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-cover.jpg&quot;);
        background-position: top;
      ">
            <span class="mask bg-gradient-dark opacity-6"></span>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5 text-center mx-auto">
                        <h1 class="display-5 text-white mb-1 mt-1">Cadastro de profissional</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
                <div class="col-md-10 mx-auto">
                    <div class="card z-index-0">
                        <div class="card-header text-center p-4 pb-0">
                            <p class="text-start">
                                Veja aqui as instruções para cadastrar um profissional.
                            </p>
                        </div>
                        <div class="card-body pt-0">

                            <button v-if="!selectObjectivesView" type="button" class="btn btn-outline-secondary"
                                @click="handleReturn()"><i class="fa-solid fa-arrow-left"></i>
                                Voltar</button>

                            <div class="row">
                                <TimeServices />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
