<template>
    <div>
        <div class="row">
            <div class="card">
                <div class="card-header pb-0">
                    <h6>Agenda do Profissional</h6>
                </div>
                <div class="card-body px-0 pt-0 pb-2">
                    <!-- Seletor de dias disponíveis -->
                    <div class="row pb-5">
                        <div class="col-12">
                            <select v-model="selectedDay" class="form-select">
                                <option value="all">Próximos 7 dias</option>
                                <option v-for="(day, index) in days" :key="index" :value="index">{{ day.date }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <!-- Colunas para cada dia -->
                    <div class="row">
                        <template v-for="(day, index) in days" :key="index">
                            <div class="col-12 col-md" v-if="selectedDay == index || selectedDay == 'all'">
                                <h5 class="text-center">{{ formatDateToDMY(day.date) }}</h5>
                                <ul class="list-group">
                                    <li v-for="(timeslot, i) in day.timeslots" :key="i" class="list-group-item"
                                        @click="handleTimeslotClick(day, timeslot)" :class="handleClassForDay(timeslot)"
                                        data-toggle="modal" :data-target="'#appointmentModal' + index + i">

                                        <span>{{ timeslot.time }} - </span>

                                        <template v-if="timeslot.schedule.client">
                                            {{ getFirstName(timeslot.schedule.client) }}
                                        </template>

                                        <template v-else-if="!timeslot.schedule.client">
                                            {{ timeslot.schedule.service }}
                                        </template>

                                        <template v-else>
                                            Disponível
                                        </template>
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </div>

                </div>
            </div>
        </div>

        <!-- Popup para criar agendamento manual -->
        <div class="modal show-modal" :id="modalId" tabindex="2" role="dialog" aria-labelledby="appointmentModalLabel"
            aria-hidden="true" v-if="selectedTimeslot">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Criar Agendamento</h5>
                        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Fechar"
                            @click="closeModal">
                            <span style="color: #000; font-size: 21px; font-weight: 600;">x</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="clientName">Nome do Cliente:</label>
                            <input type="text" class="form-control" id="clientName" v-model="clientName"
                                placeholder="Digite o nome do cliente">
                        </div>
                        <div class="form-group">
                            <label for="clientPhone">Telefone do Cliente:</label>
                            <input type="text" class="form-control" id="clientPhone" v-model="clientPhone"
                                placeholder="Digite o nome do cliente">
                        </div>
                        <div class="form-group">
                            <label class="form-label">Selecione um Serviço:</label>
                            <select class="form-select" v-model="serviceId">
                                <option v-for="service in services" :key="service.id" :value="service.id">{{
                                    service.title }}</option>
                            </select>
                        </div>
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="blockTimeslot" v-model="blockTimeslot">
                            <label class="form-check-label" for="blockTimeslot">Bloquear Horário</label>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="closeModal">Cancelar</button>
                        <button type="button" class="btn btn-primary" @click="createAppointment">Salvar</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal para detalhes do pedido -->
        <div class="modal show-modal" id="unavailableTimeslotModal" tabindex="-1" role="dialog"
            aria-labelledby="unavailableTimeslotModalLabel" aria-hidden="true" v-if="unavailableTimeslotModalData">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Detalhes do Pedido</h5>
                        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Fechar"
                            @click="closeModal">
                            <span style="color: #000; font-size: 21px; font-weight: 600;">x</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>Dia: {{ formatDateToDMY(unavailableTimeslotModalData.day) }}</p>
                        <p>Horário: {{ unavailableTimeslotModalData.time }}</p>
                        <p>Cliente: {{ unavailableTimeslotModalData.schedule.client }}</p>
                        <p>Serviço: {{ unavailableTimeslotModalData.schedule.service || 'Serviço não configurado' }}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="closeModal">Fechar</button>
                        <!-- Adicione mais botões ou funcionalidades conforme necessário -->
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import { getAuth } from 'firebase/auth';
export default {
    data() {
        return {
            selectedDay: 'all',
            days: [],
            services: [],
            selectedTimeslot: null,
            clientName: '',
            clientPhone: '',
            serviceId: null,
            blockTimeslot: false,
            unavailableTimeslotModalData: null,
        };
    },

    mounted() {
        this.fetchShedule(); // Recupera agenda de usuário
        this.fetchServices(); // Pré popula select de serviços do usuário
    },

    methods: {
        filteredDays() {
            // Filtrar os dias com base na data selecionada
            if (this.selectedDay) {
                return this.days.filter(day => day.date === this.selectedDay);
            } else {
                // Se nenhum dia estiver selecionado, mostrar os próximos 7 dias
                const currentDate = new Date();
                const endDate = new Date(currentDate);
                endDate.setDate(currentDate.getDate() + 7);

                return this.days.filter(day => {
                    const dayDate = new Date(day.date);
                    return dayDate >= currentDate && dayDate <= endDate;
                });
            }
        },

        getFirstName(fullName) {
            if (fullName) {
                // Faz o split no espaço para obter o primeiro nome
                const firstName = fullName.split(' ')[0];
                return firstName;
            } else {
                return ''; // Retorna vazio se o nome completo não estiver disponível
            }
        },
        handleClassForDay(timeslot) {
            let dayClass = '';
            if (timeslot.schedule.blockedTime) {
                dayClass = 'list-group-item-danger';
            } else if (timeslot.schedule.client) {
                dayClass = 'list-group-item-info';
            } else if (timeslot.schedule.service) {
                dayClass = 'list-group-item-info';
            } else {
                dayClass = 'bg-success text-white';
            }

            return dayClass;
        },

        showUnavailableTimeslotModal(day, time, schedule) {
            this.unavailableTimeslotModalData = { day, time, schedule };
        },

        handleTimeslotClick(day, timeslot) {
            if (timeslot.schedule.client || timeslot.schedule.service) {
                this.showUnavailableTimeslotModal(day.date, timeslot.time, timeslot.schedule);
                this.selectedTimeslot = null;
            } else {
                // Se o timeslot estiver disponível
                this.selectedTimeslot = { day, timeslot };
                console.log(this.selectedTimeslot)
            }
        },
        closeModal() {
            this.unavailableTimeslotModalData = null;
            this.selectedTimeslot = null;
            this.clientName = '';
            this.clientPhone = '';
            this.serviceId = null;
            this.blockTimeslot = false;
        },
        createAppointment() {
            // Capturar os dados do agendamento
            const { day, timeslot } = this.selectedTimeslot;
            const { time } = timeslot;
            const dateTime = `${day.date} ${time}`;

            // Converter o dateTime para o formato ISO 8601
            const formattedDateTime = this.convertToISO8601(dateTime);

            // Montar o objeto de dados para enviar na requisição
            const requestData = {
                dateTime: formattedDateTime,
                name: this.clientName,
                phone: this.clientPhone,
                serviceId: this.serviceId, // Supondo que serviceName seja o ID do serviço selecionado
                professionalId: this.$route.params.id, // Supondo que o ID do profissional seja obtido da rota
                blockedTime: this.blockTimeslot,
            };

            // Obter o token de autenticação
            const getAuthToken = async () => {
                const auth = getAuth();
                const user = auth.currentUser;

                if (!user) {
                    console.error('User not authenticated.');
                    return null;
                }

                return await user.getIdToken();
            };

            // Enviar a requisição HTTP
            getAuthToken().then(token => {
                if (token) {
                    axios.post(process.env.URL_API_ACTION_PAGE + 'shedules/schedule-professional-post', requestData, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    })
                        .then(response => {
                            // Verificar se a requisição foi bem sucedida
                            console.log('Agendamento criado com sucesso:', response.data);

                            // Atualizar a agenda após criar o agendamento
                            this.fetchShedule();
                        })
                        .catch(error => {
                            // Tratar erros de requisição
                            console.error('Erro ao criar agendamento:', error);
                        });
                }
            });

            // Feche o popup
            this.closeModal();
        },
        convertToISO8601(dateTime) {
            // Dividir a data e a hora
            const [date, time] = dateTime.split(' ');

            // Formatar a data para "YYYY-MM-DD"
            const formattedDate = date.split('/').reverse().join('-');

            // Combinar a data e a hora no formato ISO 8601
            const iso8601DateTime = `${formattedDate}T${time}:00`;

            return iso8601DateTime;
        },

        formatDateToDMY(dateString) {
            // Verifica se a string da data está no formato esperado
            if (!dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
                console.error('Formato de data inválido');
                return '';
            }

            // Divide a string da data em ano, mês e dia
            const [year, month, day] = dateString.split('-');

            // Cria uma nova data com os componentes separados
            const dateObject = new Date(year, month - 1, day);

            // Formata a data para "dd/mm/yyyy"
            const formattedDate = dateObject.toLocaleDateString('pt-BR');

            return formattedDate;
        },

        async fetchShedule() {
            try {
                this.loading = true;
                const auth = getAuth();
                const user = auth.currentUser;

                if (!user) {
                    console.error('User not authenticated.');
                    return;
                }

                const token = await user.getIdToken();
                const professionalId = this.$route.params.id;
                const response = await axios.get(process.env.URL_API_ACTION_PAGE + 'shedules/schedule-professional/' + professionalId,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    });
                this.days = response.data.schedule;
            } catch (error) {
                console.error('Erro ao buscar agenda:', error);
            } finally {
                this.loading = false;
            }
        },
        async fetchServices() {
            try {
                this.loading = true;
                const auth = getAuth();
                const user = auth.currentUser;

                if (!user) {
                    console.error('User not authenticated.');
                    return;
                }

                const token = await user.getIdToken();
                const professionalId = this.$route.params.id;
                const response = await axios.get(process.env.URL_API_ACTION_PAGE + 'service/by-professional/' + professionalId,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    });
                this.services = response.data;
            } catch (error) {
                console.error('Erro ao buscar serviços:', error);
            } finally {
                this.loading = false;
            }
        },
    }
};
</script>

<style scoped>
.list-group-item {
    font-size: 16px;
}

.list-group-item:hover {
    cursor: pointer;
}

.show-modal {
    display: block !important;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999999;
}
</style>