<template>
    <div>
        <h4>Cadastrar serviços e tempo de serviço</h4>

        <div class="row">
            <div class="card">
                <div class="card-body">
                    <!-- Lista de serviços -->
                    <div class="mb-3" v-for="(service, index) in services" :key="index">
                        <template v-if="!service.isDeleted">
                            <label class="form-label">Serviço {{ index + 1 }}:</label>
                            <div class="row">
                                <div class="col"><input type="text" class="form-control mb-2" v-model="service.title"
                                        placeholder="Nome do Serviço"></div>
                                <div class="col"><input type="number" class="form-control mb-2"
                                        v-model="service.duration" placeholder="Tempo de Execução em Minutos"></div>
                                <div class="col"><input type="text" class="form-control" v-model="service.price"
                                        placeholder="Preço"></div>
                                <div class="col-1">
                                    <p class="text-danger trash" @click="deleteService(index)"><i
                                            class="fa-solid fa-trash"></i></p>
                                </div>
                            </div>
                        </template>
                    </div>

                    <button class="btn btn-primary mb-3" @click="addService">Adicionar Serviço</button>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12">
                <button class="btn btn-primary" @click="submitServices" style="width: 100%">Salvar
                    Agenda</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { useToast } from "vue-toastification";

export default {
    data() {
        return {
            services: [{ title: '', duration: '', price: '', isNew: true }],
            toast: useToast(),
        }
    },
    methods: {
        async fetchServices() {
            try {
                const auth = getAuth();
                const user = auth.currentUser;

                if (!user) {
                    console.error('User not authenticated.');
                    return;
                }

                const token = await user.getIdToken();

                const response = await axios.get(process.env.URL_API_ACTION_PAGE + 'service', {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                });

                // Verifica se o retorno da API não está vazio
                if (response.data.length > 0) {
                    this.services = response.data;
                }

            } catch (error) {
                console.error('Erro ao buscar os serviços:', error);
                this.toastError();
            }
        },
        toastSuccess() {
            this.toast.success("Serviços atualizados com sucesso!", {
                position: "bottom-right",
                timeout: 5728,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
        },
        toastError() {
            this.toast.error("Ocorreu um erro ao enviar as informações!", {
                position: "bottom-right",
                timeout: 5728,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
        },
        async submitServices() {
            try {
                this.loading = true;
                const auth = getAuth();
                const user = auth.currentUser;

                if (!user) {
                    console.error('User not authenticated.');
                    return;
                }

                const token = await user.getIdToken();

                const data = this.prepareServicesData();

                const response = await axios.post(process.env.URL_API_ACTION_PAGE + 'service/multiple', data, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                });
                console.log('Service:', response.data);
                this.toastSuccess();

            } catch (error) {
                console.error('Erro ao enviar os dados dos serviços:', error);
                this.toastError();
            }
        },
        prepareServicesData() {
            const createdServices = [];
            const updatedServices = [];
            const deletedServices = [];

            for (const service of this.services) {
                if (service.isNew) {
                    createdServices.push({
                        title: service.title,
                        duration: service.duration,
                        price: service.price
                    });
                } else if (service.isDeleted) {
                    deletedServices.push(service.id);
                } else {
                    updatedServices.push({
                        id: service.id,
                        title: service.title,
                        duration: service.duration,
                        price: service.price
                    });
                }
            }

            return { createdServices, updatedServices, deletedServices };
        },
        addService() {
            this.services.push({ title: '', duration: '', price: '', isNew: true });
        },
        deleteService(index) {
            const service = this.services[index];
            if (!service.isNew) {
                service.isDeleted = true;
            } else {
                this.services.splice(index, 1);
            }
        }
    },
    mounted() {
        this.fetchServices();
    }
};
</script>
