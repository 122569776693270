<script>
// import { onBeforeUnmount, onBeforeMount, onMounted } from "vue";
import { useStore } from "vuex";
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import socket from '@/services/socketService';
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";

export default {
    components: {
        ArgonInput,
        ArgonButton
    },

    data() {
        return {
            nameSessionForm: '',
            phoneSessionForm: '',
            formVerificationCode: '',
            sessionName: '',
            qrCode: '',
            conected: false,
            showQrCode: false,
            showValidCode: false,
            sessionId: '',
            errorMessage: '',
            clientIdReturned: '',
            userToken: ''
        };
    },

    methods: {
        async handleSendNameSession() {
            try {
                this.loading = true;
                const auth = getAuth();
                const user = auth.currentUser;

                if (!user) {
                    console.error('User not authenticated.');
                    return;
                }

                const token = await user.getIdToken();
                const response = await axios.post(process.env.URL_API_WWJS_BACK + 'sessions/create', {
                    sessionName: this.nameSessionForm,
                    phone: this.phoneSessionForm,
                },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    });
                this.sessionId = response.data.id;
                this.createSession();
                // this.showValidCode = true;
            } catch (error) {
                console.error('Erro ao buscar serviços:', error);
            } finally {
                this.loading = false;
            }
        },

        async handleValidCode() {
            try {
                const auth = getAuth();
                const user = auth.currentUser;

                if (!user) {
                    console.error('User not authenticated.');
                    return;
                }

                const token = await user.getIdToken();
                await axios.post(process.env.URL_API_WWJS_BACK + 'sessions/valid-code', {
                    sessionId: this.sessionId,
                    code: this.formVerificationCode,
                },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    });

                this.createSession();
            } catch (error) {
                console.error('Erro ao buscar serviços:', error);
            } finally {
                this.loading = false;
            }
        },

        async createSession() {
            const auth = getAuth();
            const user = auth.currentUser;

            if (!user) {
                console.error('User not authenticated.');
                return;
            }

            const token = await user.getIdToken();
            socket.emit('createSession', { sessionId: this.sessionId, token: token });
            this.showQrCode = true;
            this.showValidCode = false;
        },

        handleReturnToDash() {
            this.$router.push('/');
        },

        checkSessionSelected() {
            // Verifica se o parâmetro 'id' está presente na rota
            if (this.$route.params.id) {
                this.sessionId = this.$route.params.id;
                this.createSession();
            }
        },
    },

    mounted() {

        this.checkSessionSelected();

        socket.on('clientId', (data) => {
            console.log('Client id Received: ', data);
            this.clientIdReturned = data;
        });

        socket.on('qrCode', (qrCodeData) => {
            console.log(qrCodeData);
            this.qrCode = qrCodeData;
        });

        // Evento de conectado
        socket.on('clientOk', () => {
            console.log('Cliente conectado');
            this.qrCode = '';
            this.conected = true;
            this.showQrCode = true;
        });

        socket.on('sessionCreated', (data) => {
            if (data.status === 'success') {
                console.log(data);
            } else {
                this.errorMessage = data.message;
            }
        });
    },
    beforeMount() {
        const store = useStore();
        store.state.hideConfigButton = true;
        store.state.showNavbar = false;
        store.state.showSidenav = false;
        store.state.showFooter = true;
        document.body.classList.remove("bg-gray-100");
    },

    beforeUnmount() {
        const store = useStore();
        store.state.hideConfigButton = false;
        store.state.showNavbar = true;
        store.state.showSidenav = true;
        document.body.classList.add("bg-gray-100");
    }
};
</script>


<template>
    <main class="main-content mt-0">
        <div class="page-header align-items-start pt-3 pb-11 m-3 border-radius-lg" style="
        background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-cover.jpg&quot;);
        background-position: top;
      ">
            <span class="mask bg-gradient-dark opacity-6"></span>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5 text-center mx-auto">
                        <h1 class="display-5 text-white mb-1 mt-1">Cadastro de Agente</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
                <div class="col-md-7 mx-auto">
                    <div class="card z-index-0">

                        <div v-if="showQrCode" class="card-header text-center p-4 pb-0">
                            <div class="row">
                                <div class="col-auto">
                                    <div class="avatar avatar-xl position-relative">
                                        <img src="../assets/img/kedy.jpeg" alt="profile_image"
                                            class="shadow-sm w-100 border-radius-lg" />
                                    </div>
                                </div>
                                <div class="col-auto my-auto">
                                    <div class="h-100">
                                        <h5 class="mb-1 text-start">{{ nameSessionForm }}</h5>
                                        <p class="mb-0 font-weight-bold text-sm text-start">ClientID:
                                            {{ clientIdReturned }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-body">

                            <template v-if="!showQrCode && !showValidCode">
                                <h5>Dê um nome para seu Agente Virtual para Iniciar Treinamento</h5>
                                <argon-input id="name" type="text" placeholder="Nome do Agente"
                                    aria-label="Nome do Agente" v-model="nameSessionForm" />

                                <argon-input id="phone" type="phone" placeholder="11 99999-9999"
                                    aria-label="Número do WhatsApp (Com DDD)" v-model="phoneSessionForm" />

                                <div class="text-center">

                                    <argon-button fullWidth color="success" variant="gradient" class="my-4 mb-2"
                                        @click="handleSendNameSession">
                                        CRIAR E CONECTAR COM WHATSAPP
                                    </argon-button>
                                    <argon-button fullWidth color="outline-success" variant="gradient"
                                        class="my-4 mb-2 btn-outline-success" @click="handleReturnToDash">
                                        VOLTAR PARA A DASHBOARD
                                    </argon-button>
                                </div>
                            </template>

                            <template v-if="showValidCode">
                                <h5>Enviamos um código para o Whats App digitado por favor digite o código abaixo para
                                    confirmar:</h5>
                                <argon-input id="code" type="text" placeholder="Digite o código aqui"
                                    aria-label="Código de verificação" v-model="formVerificationCode" />

                                <div class="text-center">

                                    <argon-button fullWidth color="success" variant="gradient" class="my-4 mb-2"
                                        @click="handleValidCode">
                                        VALIDAR CÓDIGO
                                    </argon-button>
                                </div>
                            </template>


                            <div class="text-center">
                                <template v-if="showQrCode">

                                    <template v-if="!conected">
                                        <p v-if="qrCode">Escaneie o QRCode abaixo para adicionar o dispositivo do agente
                                            no
                                            seu
                                            WhatsApp</p>
                                        <div v-if="qrCode">
                                            <img :src="qrCode" />
                                        </div>
                                        <p v-if="!qrCode">Código QR sendo gerado</p>
                                        <img v-if="!qrCode" src="../assets/img/load.gif" height="150px" />
                                    </template>

                                    <template v-if="conected">
                                        <img src="../assets/img/check.png" height="150px" />

                                        <argon-button fullWidth color="outline-success" variant="gradient"
                                            class="my-4 mb-2 btn-outline-success" @click="handleReturnToDash">
                                            VOLTAR PARA A DASHBOARD
                                        </argon-button>
                                    </template>

                                </template>



                                <!-- Exibir mensagem de erro -->
                                <template v-if="errorMessage">
                                    <p>{{ errorMessage }}</p>
                                </template>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
