<script setup>
import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";

import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { getAuth } from 'firebase/auth';

const body = document.getElementsByTagName("body")[0];

const store = useStore();

const configPayment = async () => {
    try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
            console.error('User not authenticated.');
            return;
        }

        const token = await user.getIdToken();

        const response = await fetch(process.env.URL_API_ACTION_PAGE + 'user/config-payment', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });

        const data = await response.json();
        if (data.checkoutUrl) {
            window.location.href = data.checkoutUrl;
        } 
    } catch (error) {
        // Lidar com erros de rede
        console.error('Erro de rede ao registrar usuário:', error.message);
    }
};


onBeforeMount(() => {
    store.state.hideConfigButton = true;
    store.state.showNavbar = false;
    store.state.showSidenav = false;
    store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
    store.state.hideConfigButton = false;
    store.state.showNavbar = true;
    store.state.showSidenav = true;
    store.state.showFooter = true;
    body.classList.add("bg-gray-100");
});
</script>
<template>
    <div class="container top-0 position-sticky z-index-sticky">
        <div class="row">
            <div class="col-12">
                <navbar isBtn="bg-gradient-light" />
            </div>
        </div>
    </div>
    <main class="main-content mt-0">
        <div class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg" style="
        background-color: #2dce89 !important;
      ">

            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5 text-center mx-auto">
                        <h1 class="text-white mb-2 mt-5">Seu periodo de testes acabou!</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
                <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
                    <div class="card z-index-0">

                        <div class="card-body">
                            <p class="text-lead">
                                Clique no botão abaixo e configure os pagamentos para continuar usando todas as
                                funcionalidades da KedyBee.
                            </p>
                            <argon-button fullWidth color="dark" variant="gradient" class="my-4 mb-2"
                                @click="configPayment">CONFIGURAR FORMA DE PAGAMENTO</argon-button>
                            <p class="text-sm mt-3 mb-0">
                                Ficou com alguma dúvida?
                                <a href="javascript:;" class="text-dark font-weight-bolder">CLIQUE PARA FALAR COM NOSSA
                                    EQUIPE</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <app-footer />
</template>
