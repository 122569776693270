<style scoped>
.chat-container {
    height: 450px;
    overflow-y: auto;
}

.message {
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    word-wrap: break-word;
}

.user {
    background-color: #f0f0f0;
    text-align: right;
}

.agent {
    background-color: #cce5ff;
    text-align: left;
}

.input-container {
    display: flex;
    align-items: center;
    padding: 10px;
}

.btn-send {
    margin-left: 10px;
}

.icon-select-type {
    font-size: 38px
}

.card-border {
    border: 2px solid grey;
}

.card-border:hover {
    border-color: #2dce89;
    cursor: pointer;
}

.card-border:hover p {
    color: #2dce89;
}
</style>

<script>
import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";

import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";

import axios from 'axios';
import { getAuth } from 'firebase/auth';

// import ArgonTextarea from "@/components/ArgonTextarea.vue";

export default {
    components: {
        ArgonInput,
        ArgonButton
    },
    setup() {
        const store = useStore();

        onBeforeMount(() => {
            store.state.hideConfigButton = true;
            store.state.showNavbar = false;
            store.state.showSidenav = false;
            store.state.showFooter = true;
            document.body.classList.remove("bg-gray-100");
        });

        onBeforeUnmount(() => {
            store.state.hideConfigButton = false;
            store.state.showNavbar = true;
            store.state.showSidenav = true;
            document.body.classList.add("bg-gray-100");
        });

        return {};
    },

    data() {
        return {
            dataTraining: [],
            loading: false,
            agentId: '',
        };
    },
    methods: {
        handleReturn() {
            this.$router.push({ name: 'Agents' });
        },

        async submitTraining() {
            try {
                this.loading = true;
                const auth = getAuth();
                const user = auth.currentUser;

                if (!user) {
                    console.error('User not authenticated.');
                    return;
                }
                const data = {
                    ...this.dataTraining,
                    agentId: this.agentId
                };

                const token = await user.getIdToken();
                await axios.post( process.env.URL_API_WWJS_BACK + 'session-training', data, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                });

                this.$router.push({ path: '/agents', query: { toast: 'success' } });

            } catch (error) {
                console.error('Erro ao enviar os dados:', error);
            } finally {
                this.loading = false;
            }
        },

        checkAgentSelected() {
            this.agentId = this.$route.params.id;
        },

        async fetchSessionTraining() {
            try {
                this.loading = true;
                const auth = getAuth();
                const user = auth.currentUser;

                if (!user) {
                    console.error('User not authenticated.');
                    return;
                }

                const token = await user.getIdToken();
                const response = await axios.get(process.env.URL_API_WWJS_BACK + 'session-training/by-session/' + this.agentId, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                });

                this.dataTraining = response.data;
                console.log(this.dataTraining);

            } catch (error) {
                console.error('Erro ao enviar os dados:', error);
            } finally {
                this.loading = false;
            }
        }
    },
    mounted() {
        this.checkAgentSelected();
        this.fetchSessionTraining();
    }
};
</script>

<template>
    <main class="main-content mt-0">
        <div class="page-header align-items-start pt-3 pb-11 m-3 border-radius-lg" style="
        background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-cover.jpg&quot;);
        background-position: top;
      ">
            <span class="mask bg-gradient-dark opacity-6"></span>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5 text-center mx-auto">
                        <h1 class="display-5 text-white mb-1 mt-1">Treinamento de Agente</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
                <div class="col-md-10 mx-auto">
                    <div class="card z-index-0">
                        <div class="card-header text-center p-4 pb-0">
                            <p class="text-start">
                                Veja aqui as instruções para cadastrar um profissional.
                            </p>
                        </div>
                        <div class="card-body pt-0">

                            <button v-if="!selectObjectivesView" type="button" class="btn btn-outline-secondary"
                                @click="handleReturn()"><i class="fa-solid fa-arrow-left"></i>
                                Voltar</button>

                            <div class="row">

                                <div class="col-12 col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Nome do agente</label>
                                        <argon-input id="agentName" type="text" placeholder="Nome do Agente"
                                            name="agentName" v-model="dataTraining.agentName" size="lg" />
                                    </div>
                                </div>

                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Sexo</label>
                                        <select class="form-select" v-model="dataTraining.agentSex">
                                            <option value="male">Masculino</option>
                                            <option value="female">Feminina</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-12 col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Nome do produto ou empresa</label>
                                        <argon-input id="productName" type="text"
                                            placeholder="Nome do Produto ou Empresa" name="productName"
                                            v-model="dataTraining.productName" size="lg" />
                                    </div>
                                </div>

                                <div class="col-12 col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Principal Objetivo do Agente</label>
                                        <select class="form-select" v-model="dataTraining.missionAgent">
                                            <option value="delivery">Atedimento em delivery</option>
                                            <option value="shedule">Agendamento de Serviços ou Consultas</option>
                                            <option value="infoproduct">Atendimento para Venda de Infoprodutos</option>
                                            <option value="private-label">Produtos de Marca Própria</option>
                                            <option value="client-suport">Suporte ao Cliente</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="mb-3">
                                        <div class="form-group">
                                            <label for="instructionAgent">Descreva seu produto ou serviço</label>
                                            <textarea id="instructionAgent" class="form-control" rows="6"
                                                v-model="dataTraining.instructionAgent"
                                                placeholder="Missão Principal do Agente"></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="mb-3">
                                        <div class="form-group">
                                            <label for="instructionAgent">Quais as perguntas frequentes de seu cliente?</label>
                                            <textarea id="instructionAgent" class="form-control" rows="6"
                                                v-model="dataTraining.frequentlyQuestions"
                                                placeholder="Missão Principal do Agente"></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="text-center">
                                        <argon-button @click="submitTraining" class="mt-4" variant="gradient"
                                            color="success" fullWidth size="lg">SALVAR TREINAMENTO <span v-if="loading"
                                                class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span></argon-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
