<script setup>
import { onBeforeUnmount, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";

import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
import ArgonButton from "@/components/ArgonButton.vue";

const body = document.getElementsByTagName("body")[0];

const store = useStore();

const name = ref('');
const email = ref('');
const password = ref('');
const errorMessage = ref('');
const isLoading = ref(false); // Estado de carregamento

const signup = async () => {
  isLoading.value = true; // Inicia o carregamento
  errorMessage.value = ''; // Reseta a mensagem de erro
  try {
    const response = await fetch(process.env.URL_API_ACTION_PAGE + 'user/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: name.value,
        email: email.value,
        password: password.value
      })
    });
    const data = await response.json();
    if (data.checkoutUrl) {
      window.location.href = data.checkoutUrl;
    } else {
      errorMessage.value = 'Falha ao criar a cobrança. Tente novamente.';
    }
  } catch (error) {
    console.error('Erro de rede ao registrar usuário:', error.message);
    errorMessage.value = 'Erro de rede ao registrar usuário. Tente novamente.';
  } finally {
    isLoading.value = false; // Termina o carregamento
  }
};

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  body.classList.add("bg-gray-100");
});
</script>

<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar isBtn="bg-gradient-light" />
      </div>
    </div>
  </div>
  <main class="main-content mt-0">
    <div class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg" style="
        background-color: #2dce89 !important;
      ">
      
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h1 class="text-white mb-2 mt-5">Crie sua conta!</h1>
            <p class="text-lead text-white">
              Use o formulário abaixo para criar a sua conta no KedyAtende
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-body">
              <argon-input id="name" type="text" placeholder="Nome" aria-label="Nome" v-model="name" />
              <argon-input id="email" type="email" placeholder="E-mail" aria-label="E-mail" v-model="email" />
              <argon-input id="password" type="password" placeholder="Senha" aria-label="Senha" v-model="password" />
              <argon-checkbox checked>
                <label class="form-check-label" for="flexCheckDefault">
                  Eu aceito os
                  <a href="javascript:;" class="text-dark font-weight-bolder">Termos e condições de uso.</a>
                </label>
              </argon-checkbox>
              <div class="text-center">
                <argon-button fullWidth color="dark" variant="gradient" class="my-4 mb-2" @click="signup" :disabled="isLoading">
                  <span v-if="!isLoading">CRIAR UMA CONTA</span>
                  <span v-else>Carregando...</span>
                </argon-button>
              </div>
              <p class="text-sm mt-3 mb-0">
                Já tem uma conta?
                <a href="javascript:;" class="text-dark font-weight-bolder">CLIQUE PARA ENTRAR</a>
              </p>
              <p class="text-danger" v-if="errorMessage">
                {{ errorMessage }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>
