<template>
  <div class="card">
    <div class="card-body">
      <div class="drop-area" @dragover.prevent @drop="handleDrop" @click="selectFile">
        <input type="file" ref="fileInput" style="display: none" @change="handleFileChange">
        <div v-if="!file && !imageUrl">
          <p>Arraste e solte a imagem aqui ou clique para selecionar</p>
        </div>
        <div v-else>
          <img v-if="imageUrl" :src="imageUrl" alt="Imagem enviada">
          <p v-else>{{ file.name }}</p>
        </div>
      </div>
      <button type="button" class="btn btn-secondary mt-2 mb-0" @click="uploadImage" style="width: 100%">Enviar
        imagem</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { getAuth } from 'firebase/auth';
export default {
  data() {
    return {
      file: null,
      imageUrl: null
    };
  },
  methods: {
    handleFileChange(event) {
      this.file = event.target.files[0];
    },
    async handleDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        this.file = files[0];
      }
    },
    selectFile() {
      this.$refs.fileInput.click();
    },
    async uploadImage() {
      try {
        const formData = new FormData();
        formData.append('image', this.file);

        const auth = getAuth(); // Obtém o objeto de autenticação do Firebase
        const user = auth.currentUser; // Obtém o usuário atualmente autenticado
        const token = await user.getIdToken(); // Obtém o token de ID do usuário

        const response = await axios.post(process.env.URL_API_ACTION_PAGE + 'upload-image', formData, {
          headers: {
            'Authorization': `Bearer ${token}`, // Define o token de autorização Bearer
            'Content-Type': 'multipart/form-data' // Define o tipo de conteúdo como form-data
          }
        });

        this.imageUrl = response.data.image_url; // Supondo que a resposta da API tenha uma propriedade 'imageUrl'

        // Você pode enviar a URL da imagem para o componente pai usando eventos ou Vuex, por exemplo
        this.$emit('imageUploaded', this.imageUrl);
      } catch (error) {
        console.error('Erro ao enviar imagem:', error);
      }
    }
  }
};
</script>

<style scoped>
.drop-area {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.drop-area img {
  max-width: 100%;
  max-height: 200px;
}
</style>