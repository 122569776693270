<script>
import axios from 'axios';
import { getAuth } from 'firebase/auth';

export default {
  data() {
    return {
      sessions: [],
      loading: false
    };
  },
  mounted() {
    this.fetchSessions();
  },
  methods: {
    async fetchSessions() {
      try {
        this.loading = true;
        const auth = getAuth();
        const user = auth.currentUser;
        
        if (!user) {
          console.error('User not authenticated.');
          return;
        }

        const token = await user.getIdToken();
        const response = await axios.get(process.env.URL_API_WWJS_BACK + 'sessions/', {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        
        this.sessions = response.data; // Supondo que o retorno seja uma lista de sessões
      } catch (error) {
        console.error('Erro ao buscar sessões:', error);
      } finally {
        this.loading = false;
      }
    },

    training(id) {
      console.log(id)
      this.$router.push({ name: 'TrainingAgent', params: { id: id } });
    },

    conectAgent(id) {
      this.$router.push({ name: 'AddAgent', params: { id: id } });
    },
  }
};
</script>

<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Agentes Conectados</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Agente</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
              <th class="text-secondary opacity-7"></th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="session in sessions" :key="session.id">
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <img
                      src="../../assets/img/kedy.jpeg"
                      class="avatar avatar-sm me-3"
                      alt="user1"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ session.name }}</h6>
                    <p class="text-xs text-secondary mb-0">{{ session.clientId }}</p>
                  </div>
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                <span :class="['badge', 'badge-sm', session.confirmed ? 'bg-gradient-success' : 'bg-gradient-danger']">{{  session.confirmed ? 'Ativo' : 'Confirmação Pendente' }}</span>
              </td>
              <td class="align-middle">
                <button @click="conectAgent(session.id)" class="btn btn-sm btn-secondary mb-0" data-toggle="tooltip"
                  data-original-title="Conectar">Conectar</button>
              </td>
              <td class="align-middle" style="cursor: pointer">
                <button @click="training(session.id)" class="btn btn-sm btn-secondary mb-0" data-toggle="tooltip"
                  data-original-title="Iniciar Treinamento">Iniciar Treinamento</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="loading" class="text-center mt-3">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span class="visually-hidden">Carregando...</span>
        </div>
      </div>
    </div>
  </div>
</template>
