import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Agents from "../views/Agents.vue";
import Profile from "../views/Profile.vue";
import Billing from "../views/Billing.vue";
import Professionals from "../views/Professionals.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import TrainingAgent from "../views/TrainingAgent.vue";
import AddAgent from "../views/AddAgent.vue";
import Shedule from "../views/Shedule.vue";
import AddProfessional from "../views/AddProfessional.vue";
import SheduleListProfessionals from "../views/SheduleListProfessionals.vue";

import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useStore } from "vuex";
import Service from "../views/Service.vue";
import PageEditor from "../views/PageEditor.vue";

import PaymentPending from "../views/PaymentPending.vue";
import axios from 'axios'; // Importa axios para verificar o status do usuário

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
    meta: { requiresAuth: true },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/agents",
    name: "Agents",
    component: Agents,
    meta: { requiresAuth: true },
  },
  {
    path: "/add-agent/:id?",
    name: "AddAgent",
    component: AddAgent,
    meta: { requiresAuth: true },
  },
  {
    path: "/training-agent/:id",
    name: "TrainingAgent",
    component: TrainingAgent,
    meta: { requiresAuth: true },
  },
  {
    path: "/page-editor",
    name: "PageEditor",
    component: PageEditor,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: { requiresAuth: true },
  },
  {
    path: "/professionals/add/:id?",
    name: "AddProfessional",
    component: AddProfessional,
    meta: { requiresAuth: true },
  },
  {
    path: "/professionals",
    name: "Professionals",
    component: Professionals,
    meta: { requiresAuth: true },
  },
  {
    path: "/services",
    name: "Services",
    component: Service,
    meta: { requiresAuth: true },
  },
  {
    path: "/shedule",
    name: "SheduleListProfessionals",
    component: SheduleListProfessionals,
    meta: { requiresAuth: true },
  },
  {
    path: "/shedule/:id",
    name: "Shedule",
    component: Shedule,
    meta: { requiresAuth: true },
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/logout",
    name: "Logout",
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          next("/signin");
        })
        .catch((error) => {
          console.error("Erro ao fazer logout:", error);
          next(from.path);
        });
    },
  },
  {
    path: "/payment-pending",
    name: "PaymentPending",
    component: PaymentPending,
  },
  {
    path: "/payment-canceled",
    name: "PaymentCanceled",
    component: PaymentPending,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const store = useStore();
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const auth = getAuth();

  onAuthStateChanged(auth, async (user) => {
    if (requiresAuth && !user) {
      if (to.path !== "/signin") {
        next("/signin");
      } else {
        next();
      }
    } else if (requiresAuth && user) {
      try {
        const token = await user.getIdToken();

        // Verifique se o status do usuário já foi definido no Vuex
        if (!store.state.userStatus) {
          const response = await axios.get( process.env.URL_API_ACTION_PAGE + 'user/valid', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

          const status = response.data.status;
          store.commit('setUserStatus', status);

          if (status === "past_due" || status === "incomplete" || status === "incomplete_expired") {
            next("/payment-pending");
          } else if (status === "canceled") {
            next("/payment-canceled");
          } else {
            next();
          }
        } else {
          // Use o status do usuário armazenado no Vuex
          const status = store.state.userStatus;

          if (status === "past_due" || status === "incomplete" || status === "incomplete_expired") {
            next("/payment-pending");
          } else if (status === "canceled") {
            next("/payment-canceled");
          } else {
            next();
          }
        }
      } catch (error) {
        console.error('Erro ao verificar status do usuário:', error);
        store.commit('clearUserStatus');
        next("/signin");
      }
    } else {
      next();
    }
  });
});

export default router;
