import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import ArgonDashboard from "./argon-dashboard";
import { initializeApp } from "firebase/app";

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDp6AGWdAmycIucanLYG8EwQzdGWOSylpM",
  authDomain: "kedyatende-ai.firebaseapp.com",
  projectId: "kedyatende-ai",
  storageBucket: "kedyatende-ai.appspot.com",
  messagingSenderId: "178336985640",
  appId: "1:178336985640:web:2c5eb5f314cd532f397b1d"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const appInstance = createApp(App);
appInstance.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
});

appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.config.globalProperties.$firebase = firebaseApp; // Adicionando o Firebase à instância do Vue para acesso global
appInstance.mount("#app");
