<template>
    <main class="main-content mt-0">
        <div class="page-header align-items-start pt-3 pb-11 m-3 border-radius-lg" style="
        background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-cover.jpg&quot;);
        background-position: top;
      ">
            <span class="mask bg-gradient-dark opacity-6"></span>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5 text-center mx-auto">
                        <h1 class="display-5 text-white mb-1 mt-1">Edite as informações</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
                <div class="col-md-10 mx-auto">
                    <div class="card z-index-0">
                        <div class="card-header text-center p-4 pb-0">
                            <p class="text-start">
                                Veja aqui as instruções para <a href="https://google.com">editar sua página aqui</a>.
                            </p>
                        </div>
                        <div class="card-body pt-0">

                            <button v-if="!selectObjectivesView" type="button" class="btn btn-outline-secondary"
                                @click="handleReturn()"><i class="fa-solid fa-arrow-left"></i>
                                Voltar</button>

                            <div class="row">
                                <div class="col-12 col-md-6">

                                    <div class="mb-3" v-if="dataEditPage.slug !== ''">
                                        <label class="form-label">Link da sua página</label>
                                        <p>
                                            <a :href="urlFrontAction + dataEditPage.slug">
                                                {{ urlFrontAction + dataEditPage.slug }}
                                            </a>
                                        </p>
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label">Logo da Empresa</label>
                                        <UploadImg @imageUploaded="handleLogoUpload" />
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label">Imagem de capa</label>
                                        <UploadImg @imageUploaded="handleCoverUpload" />
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label" for="businessName">Nome do estabelecimento:</label>
                                        <input class="form-control" type="text" id="businessName"
                                            v-model="dataEditPage.businessName">
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label" for="businessAddress">Endereço do
                                            estabelecimento:</label>
                                        <input class="form-control" type="text" id="businessAddress"
                                            v-model="dataEditPage.businessAddress">
                                    </div>

                                    <button type="button" class="btn btn-secondary mt-2" @click="saveEditPage"
                                        style="width: 100%">Salvar edições</button>

                                </div>
                                <div class="col-12 col-md-6 rounded" style="border: 2px solid #010101">
                                    <PreviewEditPage :data="dataEditPage" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";
import UploadImg from '@/views/components/UploadImg.vue';
import PreviewEditPage from '@/views/components/PreviewEditPage.vue';

import axios from 'axios';
import { getAuth } from 'firebase/auth';

export default {
    components: {
        UploadImg,
        PreviewEditPage
    },
    setup() {
        const store = useStore();

        onBeforeMount(() => {
            store.state.hideConfigButton = true;
            store.state.showNavbar = false;
            store.state.showSidenav = false;
            store.state.showFooter = true;
            document.body.classList.remove("bg-gray-100");
        });

        onBeforeUnmount(() => {
            store.state.hideConfigButton = false;
            store.state.showNavbar = true;
            store.state.showSidenav = true;
            document.body.classList.add("bg-gray-100");
        });

        return {};
    },

    data() {
        return {
            dataEditPage: {
                coverImg: '',
                logoImg: '',
                businessName: '',
                businessAddress: '',
                slug: ''
            },
            urlFrontAction: process.env.URL_ACTION_PAGE_FRONT
        };
    },
    methods: {
        handleReturn() {
            this.$router.push({ name: 'Dashboard' });
        },
        handleCoverUpload(imgUrl) {
            this.dataEditPage.coverImg = imgUrl;
        },
        handleLogoUpload(imgUrl) {
            this.dataEditPage.logoImg = imgUrl;
        },
        async saveEditPage() {
            // Se tiver um id na rota, chama saveUpdateEditPage, senão saveNewEditPage
            if (this.dataEditPage.id) {
                await this.saveUpdateEditPage();
            } else {
                await this.saveNewEditPage();
            }
        },
        async saveNewEditPage() {
            try {
                const auth = getAuth(); // Obtém o objeto de autenticação do Firebase
                const user = auth.currentUser; // Obtém o usuário atualmente autenticado
                const token = await user.getIdToken(); // Obtém o token de ID do usuário

                const response = await axios.post(process.env.URL_API_ACTION_PAGE + 'action-page', this.dataEditPage, {
                    headers: {
                        'Authorization': `Bearer ${token}`, // Define o token de autorização Bearer
                        'Content-Type': 'application/json' // Define o tipo de conteúdo como JSON
                    }
                });

                this.dataEditPage = response.data;

            } catch (error) {
                console.error('Erro ao salvar nova página:', error);
            }
        },
        async saveUpdateEditPage() {
            try {
                const auth = getAuth(); // Obtém o objeto de autenticação do Firebase
                const user = auth.currentUser; // Obtém o usuário atualmente autenticado
                const token = await user.getIdToken(); // Obtém o token de ID do usuário

                const response = await axios.put(process.env.URL_API_ACTION_PAGE + 'action-page/' + this.dataEditPage.id, this.dataEditPage, {
                    headers: {
                        'Authorization': `Bearer ${token}`, // Define o token de autorização Bearer
                        'Content-Type': 'application/json' // Define o tipo de conteúdo como JSON
                    }
                });

                this.dataEditPage = response.data;

            } catch (error) {
                console.error('Erro ao atualizar página:', error);
            }
        },
        async fetchEditData() {
            try {
                const auth = getAuth(); // Obtém o objeto de autenticação do Firebase
                const user = auth.currentUser; // Obtém o usuário atualmente autenticado
                const token = await user.getIdToken(); // Obtém o token de ID do usuário

                const response = await axios.get(process.env.URL_API_ACTION_PAGE + 'action-page-by-user/', {
                    headers: {
                        'Authorization': `Bearer ${token}`, // Define o token de autorização Bearer
                        'Content-Type': 'application/json' // Define o tipo de conteúdo como JSON
                    }
                });

                this.dataEditPage = response.data;

            } catch (error) {
                console.error('Erro ao buscar dados da página:', error);
            }
        },
    },
    mounted() {
        this.fetchEditData();
    },
};
</script>

<style scoped>
/* Estilos permanecem os mesmos */
</style>
