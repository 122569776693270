<template>
  <div class="card card-carousel overflow-hidden h-100 p-0">
    <div id="carouselExampleCaptions" class="carousel slide h-100" data-bs-ride="carousel">
      <div class="carousel-inner border-radius-lg h-100">
        <a href="/" class="carousel-item h-100 active" :style="{
          backgroundImage:
            'url(' + require('@/assets/img/carousel-1.jpg') + ')',
          backgroundSize: 'cover',
        }">
            <div class="carousel-caption d-none d-md-block bottom-0 text-start start-0 ms-5">
              <div class="icon icon-shape icon-md bg-white text-center border-radius-md mb-3">
                <i class="fa-brands fa-youtube text-dark opacity-10" style="font-size: 23px;"></i>
              </div>
              <h5 class="text-white mb-1">Aprenda a usar o KedyAtende</h5>
              <p>
                Veja em nosso canal no Youtube diversos tutoriais de uso do KedyAtende
              </p>
            </div>
        </a>

        <a href="/" class="carousel-item h-100" :style="{
          backgroundImage:
            'url(' + require('@/assets/img/carousel-2.jpg') + ')',
          backgroundSize: 'cover',
        }">
          <div class="carousel-caption d-none d-md-block bottom-0 text-start start-0 ms-5">
            <div class="icon icon-shape icon-md bg-white text-center border-radius-md mb-3">
              <i class="fa-solid fa-lightbulb text-dark opacity-10" style="font-size: 23px;"></i>
            </div>
            <h5 class="text-white mb-1">Programa de Parceiros</h5>
            <p>
              Venha crescer com a gente e receba comissões e bônus especiais.
            </p>
          </div>
        </a>
      </div>
      <button class="carousel-control-prev w-5 me-3" type="button" data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next w-5 me-3" type="button" data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>
