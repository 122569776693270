<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import Carousel from "./components/Carousel.vue";

import axios from 'axios';
import { getAuth } from 'firebase/auth';

export default {
  components: {
    Carousel,
    MiniStatisticsCard
  },
  data() {
    return {
      sheduleDay: [],
      loading: false,
    };
  },
  mounted() {
    this.fetchShedule();
  },
  methods: {
    async fetchShedule() {
      try {
        this.loading = true;
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
          console.error('User not authenticated.');
          return;
        }

        const token = await user.getIdToken();
        const response = await axios.get(process.env.URL_API_ACTION_PAGE + 'appointment/schedule/appointments-day/', {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        this.sheduleDay = response.data; // Supondo que o retorno seja uma lista de sessões
      } catch (error) {
        console.error('Erro ao buscar sessões:', error);
      } finally {
        this.loading = false;
      }
    },
  }
};
</script>
<template>
  <div class="py-4 container-fluid h-100">
    <div class="row">
      <div class="col-lg-8 mb-lg">
        
        <div class="row mb-4" style="height: 200px;">
          <div class="col-12">
            <carousel />
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 col-12">
            <mini-statistics-card title="Hoje" value="23" description="Pessoas atendidas" :icon="{
              component: 'fa-solid fa-calendar-day',
              background: 'bg-gradient-primary',
              shape: 'rounded-circle',
            }" />
          </div>
          <div class="col-md-4 col-12">
            <mini-statistics-card title="Ultimos 7 dias" value="450" description="Pessoas atendidas" :icon="{
              component: 'fa-solid fa-calendar-week',
              background: 'bg-gradient-danger',
              shape: 'rounded-circle',
            }" />
          </div>
          <div class="col-md-4 col-12">
            <mini-statistics-card title="Faturamento do mês" value="R$ 2.439,17" description="Faturado neste mês" :icon="{
              component: 'fa-regular fa-calendar',
              background: 'bg-gradient-warning',
              shape: 'rounded-circle',
            }" />
          </div>
        </div>

      </div>
      <div class="col-lg-4">

        <div class="row mb-4">

          <div class="col">
            <div class="card">
              <div class="p-3 pb-0 card-header">
                <h6 class="mb-0">Agendamentos do Dia</h6>
              </div>
              <div class="p-3 card-body">
                <ul class="list-group">

                  <template v-if="sheduleDay">
                    <li v-for="(dayItem, index) in sheduleDay" :key="index"
                      class="mb-2 border-0 list-group-item justify-content-between border-radius-lg ps-0">
                      <div class="row">
                        <div class="col-2">
                          <div class="text-center shadow icon icon-shape icon-sm bg-gradient-dark mt-1">
                            <i class="fa-solid fa-calendar-check text-white opacity-10" style="font-size: 14px;"></i>
                          </div>
                        </div>
                        <div class="col-10">
                          <h6 class="text-sm text-dark" style="margin-bottom: 0px;">{{ (dayItem.client) ?
                            dayItem.client.name : dayItem.clientName }}</h6>
                          <span class="text-xs">Serviço: <span class="font-weight-bold">{{ (dayItem.servie) ?
                            dayItem.servie.name : '' }}</span></span>
                        </div>
                      </div>
                    </li>
                  </template>

                  <template v-else>
                    <li class="mb-2 border-0 list-group-item justify-content-between border-radius-lg ps-0">
                      <div class="row">
                        <div class="col">
                          <h6 class="text-sm text-dark" style="margin-bottom: 0px;">Nenhum agendamento registrado para
                            hoje.</h6>
                        </div>
                      </div>
                    </li>
                  </template>

                </ul>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>
