<script setup>
import { useRoute } from "vue-router";

import SidenavItem from "./SidenavItem.vue";
import SidenavCard from "./SidenavCard.vue";

const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};
</script>
<template>
  <div class="collapse navbar-collapse w-auto h-auto h-100 mb-2" id="sidenav-collapse-main">
    <ul class="navbar-nav">

      <li class="nav-item">
        <sidenav-item to="/dashboard" :class="getRoute() === 'dashboard' ? 'active' : ''" :navText="'Dashboard'">
          <template v-slot:icon>
            <i class="fas fa-tachometer-alt text-secondary opacity-10" style="font-size: 21px;"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item to="/shedule" :class="getRoute() === 'shedule' ? 'active' : ''" :navText="'Agenda'">
          <template v-slot:icon>
            <i class="fas fa-calendar-days text-secondary opacity-10" style="font-size: 21px;"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="mt-4 nav-item">
        <h6 class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6 ms-2"> GERENCIAMENTO </h6>
      </li>

      <li class="nav-item">
        <sidenav-item to="/professionals" :class="getRoute() === 'professionals' ? 'active' : ''"
          :navText="'Profissionais'">
          <template v-slot:icon>
            <i class="fa-solid fa-user-tie text-secondary opacity-10" style="font-size: 21px;"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item to="/services" :class="getRoute() === 'services' ? 'active' : ''"
          :navText="'Services'">
          <template v-slot:icon>
            <i class="fa-solid fa-bell-concierge text-secondary opacity-10" style="font-size: 21px;"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item to="/agents" :class="getRoute() === 'agents' ? 'active' : ''" :navText="'Agentes'">
          <template v-slot:icon>
            <i class="fas fa-mobile-alt text-secondary text-md opacity-10" style="font-size: 21px;"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item to="/page-editor" :class="getRoute() === 'page-editor' ? 'active' : ''" :navText="'Editar página'">
          <template v-slot:icon>
            <i class="fas fa-palette text-secondary text-md opacity-10" style="font-size: 21px;"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item to="/profile" :class="getRoute() === 'profile' ? 'active' : ''" :navText="'Minha Conta'">
          <template v-slot:icon>
            <i class="fas fa-user-cog text-secondary text-md opacity-10" style="font-size: 21px;"></i>
          </template>
        </sidenav-item>
      </li>

    </ul>
  </div>

  <div class="pt-6 mx-3 mt-3 sidenav-footer">
    <sidenav-card :card="{
      title: 'Precisa de Mais Recursos?',
      description: 'Conheça nossos planos para upgrade!',
      links: [
        {
          label: 'Comprar mais Atendentes',
          route:
            'https://www.creative-tim.com/learning-lab/vue/overview/argon-dashboard/',
          color: 'dark',
        },
        {
          label: 'Fazer Upgrade',
          route:
            'https://www.creative-tim.com/product/vue-argon-dashboard-pro?ref=vadp',
          color: 'success',
        },
      ],
    }" />
  </div>
</template>
