<script>
import axios from 'axios';
import { getAuth } from 'firebase/auth';

export default {
  data() {
    return {
      professionals: [],
      loading: false
    };
  },
  mounted() {
    this.fetchProfessionals();
  },
  methods: {
    async fetchProfessionals() {
      try {
        this.loading = true;
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
          console.error('User not authenticated.');
          return;
        }

        const token = await user.getIdToken();
        const response = await axios.get(process.env.URL_API_ACTION_PAGE + 'professional/', {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        this.professionals = response.data; // Supondo que o retorno seja uma lista de sessões
      } catch (error) {
        console.error('Erro ao buscar sessões:', error);
      } finally {
        this.loading = false;
      }
    },
    showAgenda(id) {
      this.$router.push({ name: 'Shedule', params: { id: id } });
    },
  }
};
</script>

<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Listagem de Profissionais</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Profissional</th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(professional, index) in professionals" :key="index">
              <td style="width:85%">
                <div class="d-flex px-2 py-1">
                  <div>
                    <img :src="professional.photo" class="avatar avatar-sm me-3" alt="user1" v-if="professional.photo" />
                    <img src="@/assets/img/profile-picture.jpg" class="avatar avatar-sm me-3" alt="user1" v-if="!professional.photo" />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ professional.name }}</h6>
                    <p class="text-xs text-secondary mb-0">ID:{{ professional.id }}</p>
                  </div>
                </div>
              </td>
              <td class="align-middle" style="cursor: pointer">
                <button @click="showAgenda(professional.id)" class="btn btn-sm btn-secondary" data-toggle="tooltip"
                  data-original-title="Iniciar Treinamento">Ver Agenda</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="loading" class="text-center mt-3">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span class="visually-hidden">Carregando...</span>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.trash {
  font-size: 18px;
}

.trash:hover {
  cursor: pointer;
}
</style>