<script>
import { onBeforeUnmount, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
const body = document.getElementsByTagName("body")[0];

import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

export default {
  components: {
    ArgonInput,
    ArgonButton
  },
  setup() {
    const store = useStore();
    const router = useRouter(); // Adiciona o useRouter
    const email = ref('');
    const password = ref('');
    const isLoading = ref(false); // Estado de carregamento
    const errorMessage = ref(''); // Mensagem de erro

    onBeforeMount(() => {
      store.state.hideConfigButton = true;
      store.state.showNavbar = false;
      store.state.showSidenav = false;
      store.state.showFooter = false;
      body.classList.remove("bg-gray-100");
    });
    onBeforeUnmount(() => {
      store.state.hideConfigButton = false;
      store.state.showNavbar = true;
      store.state.showSidenav = true;
      store.state.showFooter = true;
      body.classList.add("bg-gray-100");
    });

    const login = async () => {
      isLoading.value = true; // Inicia o carregamento
      errorMessage.value = ''; // Reseta a mensagem de erro
      try {
        const auth = getAuth(); // Obtenha a instância de autenticação do Firebase
        const userCredential = await signInWithEmailAndPassword(auth, email.value, password.value); // Faça login com email e senha

        // Obtenha o token de acesso do usuário autenticado
        const token = await userCredential.user.getIdToken();

        // Chame a função para enviar o token para o backend
        await sendTokenToBackend(token);
      } catch (error) {
        console.error('Erro de login:', error);
        errorMessage.value = 'Erro de login. Por favor, verifique suas credenciais e tente novamente.';
      } finally {
        isLoading.value = false; // Termina o carregamento
      }
    };

    const sendTokenToBackend = async (token) => {
      try {
        const response = await fetch(process.env.URL_API_ACTION_PAGE + 'user/valid', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        const user = await response.json(); // Extrair os dados do usuário da resposta
        store.dispatch('updateUserData', user); // Despachar a ação com os dados do usuário usando this.$store
        router.push('/');
        
      } catch (error) {
        console.error('Erro ao enviar o token para o backend:', error);
        errorMessage.value = 'Erro de rede ao enviar o token para o backend. Tente novamente.';
      }
    };

    return {
      email,
      password,
      isLoading,
      errorMessage,
      login
    };
  }
};
</script>

<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Entrar</h4>
                  <p class="mb-0">Digite seus dados de login abaixo para entrar</p>
                </div>
                <div class="card-body">
                  <div class="mb-3">
                    <argon-input id="email" type="email" placeholder="Login" name="email" v-model="email" size="lg" />
                  </div>
                  <div class="mb-3">
                    <argon-input id="password" type="password" placeholder="Senha" v-model="password" name="password" size="lg" />
                  </div>
                  <argon-switch id="rememberMe" name="remember-me">Lembrar senha</argon-switch>
                  <div class="text-center">
                    <argon-button class="mt-4" variant="gradient" color="success" fullWidth size="lg" @click="login" :disabled="isLoading">
                      <span v-if="!isLoading">ENTRAR</span>
                      <span v-else>Carregando...</span>
                    </argon-button>
                  </div>
                  <p class="text-danger" v-if="errorMessage">
                    {{ errorMessage }}
                  </p>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Não tem uma conta?
                    <a href="/signup" class="text-success text-gradient font-weight-bold">CADASTRE-SE AQUI</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column">
              <div class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg&quot;);
                       background-size: cover;">
                <span class="mask bg-gradient-success opacity-6"></span>
                <h4 class="mt-5 text-white font-weight-bolder position-relative">"Uma nova experiência de atendimento"</h4>
                <p class="text-white position-relative">Agentes virtuais de atendimento com Inteligência Artificial.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

